import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import sociverse_app_logo from "../assets/sociverse_app_logo.png";
import Dropdown from "../Features/Dropdown";
import Filter from "./Filter";

const NavBar = () => {
  const location = useLocation();
  const path = location.pathname;
  const [nav, setNav] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const navbar = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navbar("/Login");
  };
  const navRef = useRef();

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleNavigate = (link) => {
    navbar(`${link}`);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      console.log('Token Found');
      setLogin(true);
    } else {
      console.log('Token Not Found');
      setLogin(false);
    }
  });
  const handleNav = () => {
    setNav(!nav);
  };

  const navItems = [
    { id: 1, text: "Explore", link: "/" },
    { id: 2, text: "Home", link: "/Home" },
    { id: 3, text: " Create", link: "/createEvent" },
  ];

  return (
    <div>
    <div className="bg-black flex justify-between   gap-3 items-center h-20 container max-w-full mx-auto px-4 text-white">
      {/* Logo */}
      <div className="logo ">
        <div className="flex items-center gap-2 w md:w-full">
          <div onClick={handleNav} className="block lg:hidden">
            {!nav && <AiOutlineMenu size={20} />}
          </div>
          <img
            className="h-8 w-8 md:h-10 md:w-10"
            src={sociverse_app_logo}
            alt=""
          />
          <p className="font-bold text-lg lgtext-2xl hidden text-fuchsia-50 md:block ">
            Sociverse
          </p>
        </div>
      </div>
      {path === "/" && (
        <div className=" md:w-full hidden md:block">
          <Filter />
        </div>
      )}

      {/* Desktop Navigation */}
      <ul className="hidden lg:flex">
        {navItems.map((item) => (
          <Link
            to={item.link}
            key={item.id}
            className={`p-3 text-lg font-bold  hover:bg-[#7DD3FC] ${
              path === item.link && "bg-[#7DD3FC]"
            } rounded-xl m-2 cursor-pointer duration-300`}>
            {item.text}
          </Link>
        ))}
        {isLogin ? (
          <div className="m-3 cursor-pointer">
            <Dropdown />
          </div>
        ) : (
          <Link
            to="/Login"
            className="p-3 text-lg font-bold hover:bg-[#7DD3FC] rounded-xl m-2 cursor-pointer duration-300">
            Login
          </Link>
        )}
      </ul>
      {/* Mobile Navigation Icon */}
      {path === "/" && (
        <div className=" w-[80%] block md:hidden">
          <Filter />
        </div>
      )}

      {/* filter  */}

      <div onClick={handleNav} className="block lg:hidden">
        {nav && <AiOutlineClose size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
      ref={navRef}
      className={
        nav
          ? "fixed lg:hidden left-0 top-0 w-[70%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-300 z-50"
          : "ease-in-out w-[60%] duration-300 fixed top-0 bottom-0 left-[-100%]"
      }
    >
      {/* Mobile Logo */}
      <div className="logo w-full">
        <div className="flex items-center p-2 justify-between w-36">
          <img className="h-12 w-12" src={sociverse_app_logo} alt="sociverse" />
          <p className="font-bold text-fuchsia-50">Sociverse</p>
        </div>
      </div>

      {/* Mobile Navigation Items */}
      {navItems.map((item) => (
        <li
          key={item.id}
          onClick={() => handleNavigate(item.link)}
          className="p-2 mt-2 border-b rounded-xl hover:bg-sky-300 duration-300 hover:text-black cursor-pointer border-gray-600"
        >
          {item.text}
        </li>
      ))}
      {isLogin && (
        <Link to="/profile">
          <li className="p-2 mt-2 border-b rounded-xl hover:bg-sky-300 duration-300 hover:text-black cursor-pointer border-gray-600">
            Profile
          </li>
        </Link>
      )}
      {isLogin ? (
        <div>
          <li
            onClick={handleLogout}
            className="p-2 mt-2 border-b rounded-xl hover:bg-sky-300 duration-300 hover:text-black cursor-pointer border-gray-600"
          >
            Logout
          </li>
        </div>
      ) : (
        <Link to="/Login">
          <li className="p-2 mt-2 border-b rounded-xl hover:bg-sky-300 duration-300 hover:text-black cursor-pointer border-gray-600">
            Log in
          </li>
        </Link>
      )}
      {!isLogin && (
        <Link to="/signUp">
          <li className="p-2 mt-2 border-b rounded-xl hover:bg-sky-300 duration-300 hover:text-black cursor-pointer border-gray-600">
            Sign Up
          </li>
        </Link>
      )}
    </ul>
    </div>
    <div class="wrapper">
    {/* <marquee behavior="alternate"><span class="marquee">      <div class="notice">
        🚧 This site is still in development. Use Mobile App for better experience! 🚧
    </div>    </span></marquee> */}
    </div>
    </div>
  );
};

export default NavBar;
